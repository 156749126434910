<!--Responsible for -->
<template>
  <div class="container">
    <div class="columns is-centered" v-if="pagelets">
      <Pagelet
        v-for="pagelet in pagelets"
        :pageData="pagelet"
        :rootPageData="rootPageData"
        :size="panelgroupsize"
        :type="pagelet.fields.type"
        :key="pagelet.fields.title"
        :background="background"
      />
    </div>
  </div>
</template>

<script>
  import Pagelet from '../components/Pagelet.vue'
  export default {
    name: 'PanelGroup',
    props: ['pagelets', 'rootPageData', 'panelgroupsize', 'background'],
    components: {
      Pagelet
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
