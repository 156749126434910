<template>
    <section class="section"
             :class="pageData.fields.background"
             :style="backgroundImageStyle">
      <div class="container has-text-centered">
        <h1 class="title has-text-weight-light has-text-centered"
            :class="pageData.fields.order === 1?'is-size-1':'is-size-2'">
          {{pageData.fields.title}}
        </h1>
        <h2 class="subtitle is-size-4">
          {{pageData.fields.subtitle}}
        </h2>
      </div>
      <div class="hero-body">
        <div class="container has-dbl-space-below" v-if="hasHeroImage">
          <figure class="image is-3by1">
            <img
                :src="pageData.fields.heroImage.fields.file.url + '?fit=scale&w=480&h=160'"
                :srcset="`${pageData.fields.heroImage.fields.file.url}?w=640&h=214&fit=fill 640w,
                          ${pageData.fields.heroImage.fields.file.url}?w=960&h=320&fit=fill 960w,
                          ${pageData.fields.heroImage.fields.file.url}?w=1920&h=640&fit=fill 1920w`"
                sizes="calc(100vw - 64px)"
                :alt="pageData.fields.heroImage.fields.description"
            >
          </figure>
        </div>
        <div class="container has-dbl-space-below">
          <rich-text :document="pageData.fields.bodytext" :background="pageData.fields.background"></rich-text>
        </div>
        <PanelGroup :pagelets="panels1" :panelgroupsize="panels1.length"
                    :rootPageData="pageData" :background="pageData.fields.background"></PanelGroup>
        <PanelGroup :pagelets="panels2" :panelgroupsize="panels2.length"
                    :rootPageData="pageData" :background="pageData.fields.background"></PanelGroup>
      </div>
      <div class="hero-footer has-text-centered" v-if="showDownArrow">
        <font-awesome-icon :icon="['fal', 'angle-down']" :class="arrowColor"/>
      </div>
    </section>
</template>

<script>
  import PanelGroup from '../components/PanelGroup.vue'
  import RichText from '@/components/RichText.vue'

  export default {
    name: 'BannerPane',
    props: {
      pageData: Object
    },
    components: {
      PanelGroup,
      RichText
    },
    computed: {
      // bodytextHtml() {
      //   return this.convertToHTML(this.pageData.fields.bodytext);
      // },

      arrowColor() {
        let background = this.pageData.fields.background;
        return background === 'has-background-white' ? 'has-text-grey fa-4x' : 'has-text-white fa-4x' ;
      },

      showDownArrow(){
        return (this.pageData.fields.backgroundImage && this.pageData.fields.order === 1);
      },

      backgroundImageStyle() {
        let backgroundImage = this.pageData.fields.backgroundImage;
        if (backgroundImage){
          return {
            'background-image': `linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.5) 30%,
            rgba(0,0,0,0.8) 80%,rgba(0,0,0,0.9) 100%),url(${backgroundImage.fields.file.url})`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover'
          }
        }
        else {
          return ""; //no background image at all
        }
      },

      panels1() { return this.panels(1) },

      panels2() { return this.panels(2) },

      hasHeroImage() {
        let hasImage = false;
        let img = this.pageData.fields.heroImage;
        if (img && img.fields &&
          img.fields.file && img.fields.file.url != null) {
          hasImage = true;
        }
        return hasImage;
      }
    },

    methods: {
      panels(i) {
        let panels = this.pageData.fields['panels' + i] ? this.pageData.fields['panels' + i] : [];
        panels.forEach(p => {
          //this.$log.debug("{BannerPane} p.fields: ", JSON.stringify(p.fields, '', 4));
          if (p.fields.panelImage) {
            //this.$log.debug("{BannerPane} p.fields.panelImage: ", p.fields.panelImage);
          }
          // p.fields.htmlText = this.convertToHTML(p.fields.richText);  // Contentful field must be rich text
          // this.$log.debug("{BannerPane} p.fields.htmlText:", p.fields.htmlText);
        });
        let title = this.pageData.fields.title;
        // this.$log.debug("{BannerPane} title:", title, ", panels1:", JSON.stringify(panels, '', 4));
        return panels;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  //the first three to correct a bug in Bulma version 8
  @import "~bulma/sass/utilities/functions";
  @import "~bulma/sass/utilities/initial-variables";
  @import "~bulma/sass/utilities/derived-variables";
  @import "~bulma/sass/utilities/mixins";
  @import "@/scss/_bulmaOverridden";

  .hero-footer {
    padding-top: 4em;
  }
</style>

<!--
.imagesection {
height: auto;
background: linear-gradient(to bottom,#0003 0,#00000080 30%,#000c 80%,#000000e6 100%);
}-->
